<template>
    <div class="flex flex-wrap justify-center -mx-2">
        <div class="w-full grid gap-4 pb-4 grid-cols-3">
            <a-card @click="payload.loading || payload.loadingPeriod ? null : redirect({ section: 'supervising' })" v-if="payload.user.isCoordinator">
              <div class="flex items-center justify-center flex-col">
                <h3 class="text-center pb-2 w-full">Supervisando</h3>
                <div class="border-b w-full" />
                <template v-if="payload.loading || payload.loadingPeriod">
                    <a-loader class="h-10 w-10 mt-4"/>
                </template>
                <template v-else>
                    <p class="text-3xl pt-2 text-center w-full font-bold">
                      {{ payload.supervising.length }}
                    </p>
                </template>
              </div>
            </a-card>
            <a-card v-if="payload.user.isTeacher" @click="payload.loading || payload.loadingPeriod ? null : redirect({ section: 'coordinators'})">
              <div class="flex items-center justify-center flex-col">
                <h3 class="text-center w-full pb-2">Supervisores</h3>
                <div class="border-b w-full" />
                <template v-if="payload.loading || payload.loadingPeriod">
                    <a-loader class="h-10 w-10 mt-4"/>
                </template>
                <template v-else>
                    <p class="text-3xl text-center pt-2 w-full font-bold">
                        {{ payload.supervisors.length }}
                    </p>
                </template>
              </div>
            </a-card>
            <a-card @click="payload.loading || payload.loadingPeriod ? null : redirect({ section: 'courses' })">
                <div class="flex items-center justify-center flex-col">
                    <h3 class="text-center w-full pb-2">Materias</h3>
                    <div class="border-b w-full" />
                    <template v-if="payload.loading || payload.loadingPeriod">
                        <a-loader class="h-10 w-10 mt-4"/>
                    </template>
                <template v-else>
                    <p class="text-3xl text-center pt-2 w-full font-bold">
                        {{ payload.courses.count ? payload.courses.count : '0' }}
                    </p>
                </template>
                </div>
            </a-card>
            <a-card :disabled="payload.loading || payload.loadingPeriod" @click="payload.loading || payload.loadingPeriod ? null : redirect({ section: 'lessons' })">
                <div class="flex items-center justify-center flex-col">
                    <h3 class="text-center w-full pb-2">Clases</h3>
                    <div class="border-b w-full" />
                    <template v-if="payload.loading || payload.loadingPeriod">
                        <a-loader class="h-10 w-10 mt-4"/>
                    </template>
                    <template v-else>
                        <p class="text-3xl text-center pt-2 w-full font-bold">
                            {{ payload.lessonsCount }}
                        </p>
                    </template>
                </div>
            </a-card>
        </div>
        <div class="w-full px-2">
            <component
                :is="`${$route.query.section || 'courses'}-view`"
                :user="payload.user"
                :loading="payload.loading"
                :loadingPeriod="payload.loadingPeriod"
                :courses="payload.courses.data"
                :lessons="payload.lessons.flatMap($0 => $0.lessons.sort((a, b) => new Date(a.date) - new Date(b.date)))"
                :period="payload.period"
                :supervisors="payload.supervisors"
                :supervising="payload.supervising"
                :specialization="payload.specialization"
                @specialization-change="specializationChange"
            />
        </div>
    </div>
</template>

<script>
import CoordinatorsView from './coordinators'
import CoursesView from './courses'
import LessonsView from './lessons'
import SupervisingView from './supervising'
export default {
    components: {
        CoordinatorsView,
        CoursesView,
        LessonsView,
        SupervisingView
    },
    data: () => ({
        selected: {
            supervisor: null,
            specialization: null
        },
        loadings: {
            supervisor: false
        },
    }),
    methods: {
        specializationChange(id, specialization) {
            if (id && specialization) {
                this.loadings.supervisor = !this.loadings.supervisor
                this.$repository.specializations
                    .coordinator(id)
                    .then(res => {
                        this.selected.supervisor = res.data
                        this.selected.specialization = specialization
                    })
                    .finally(() => this.loadings.supervisor = !this.loadings.supervisor)
            } else {
                this.selected.specialization = null
                this.selected.supervisor = null
            }
        },
        redirect(query) {
            this.$router.replace({ query })
                .catch(() => null)
        },
    },
    computed: {
        supervisor() {
            if (this.selected.supervisor) {
                return  `${this.selected.supervisor.first_name} ${this.selected.supervisor.last_name}`
            }

            return this.payload.supervisor ? `${this.payload.supervisor.first_name} ${this.payload.supervisor.last_name}` : '-'
        }
    },
    props: {
        payload: { required: true, type: Object }
    },
    mounted() {
        if(this.payload.user.isCoordinator) {
            this.redirect({ section: 'supervising' })
        }
    }
}
</script>
