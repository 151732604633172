<template>
    <div class="bg-white rounded-2xl overflow-hidden">
        <h2 class="text-2xl p-4 mb-2">Lecciones programadas</h2>
        <a-table :source="lessons" :columns="columns" rounded :loading="loading" />
    </div>
</template>

<script>
export default {
    props: {
        lessons: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        columns: [
            { title: "Tema", key: 'subject' },
            { title: "Fecha", key: 'date' },
            { title: "Materia", key: 'course' },
            { title: "Sección", key: 'section' },
            { title: "Especialidad", key: 'specialization' },
        ],
    }),
}
</script>