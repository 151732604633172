<template>
    <div>
        <slot :payload="{
            loading,
            lessons,
            lessonsCount,
            courses,
            supervisors,
            supervising,
            specialization,
            user
        }" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    props: {
        user: { required: true, type: Object },
        period: { required: false, type: Number, default: null },
        periodId: { required: false, type: Number, default: null }
        
    },
    computed: {
        ...mapState({
            teacher: ({ teachers }) => teachers.current,
            loading: ({ teachers }) => teachers.loading
        }),
        courses() {
            return this.teacher?.courses ?? {}
        },
        lessonsCount(){
            return this.teacher?.lessons ?? 0
        },
        lessons() {
            const lessons = this.teacher?.courses.data.flatMap($0 => ({
                lessons: $0.lessons.flatMap($1 => ({
                    ...$1,
                    course: $0.course.title,
                    section: $0.section.letter_code,
                    specialization: $0.specialization.title
                }))
            }))
            return lessons ?? []
        },
        /**
         * @description just for teacher
         */
        supervisors() {
            /**
             * in this case there is not a default value
             * because is better to use its null value to
             * validate existence
             */
            return this.teacher?.supervisors ?? []
        },
        /**
         * @description just for coordinator
         */
        supervising() {
            return this.teacher?.supervising ?? []
        },
        /**
         * @description just for coordinator
         */
        specialization() {
            
            return this.teacher?.specializations ?? []
        }
    },
    methods: mapActions({
        fetch: 'teachers/showCoordinator',
        dashboard: 'teachers/coordinatorDashboard'
    }),
    watch : {
        period(val){
            if(val){
                if(this.teacher){
                    this.teacher.supervising = [];
                    this.teacher.supervisors = [];
                }
                this.fetch({
                    id: this.user.teacher.id,
                    filters: {},
                    coordFilters: {
                        teacher_id: this.user.teacher.id,
                        period_id: val,
                        // specialization_id: 2
                    }
                })
            }
        }
    },
    // mounted() {
        // this.fetch({
        //     id: this.user.teacher.id,
        //     filters: {
        //         // with: `lessons.courseSection.course,lessons.courseSection.section,lessons.courseSection.specialization,courseSections.course,courseSections.section,courseSections.specialization,courseSections.semester`
        //     },
        //     coordFilters: {
        //         teacher_id: this.user.teacher.id,
        //         period_id: 42,
        //         // specialization_id: 2
        //     }
        // })
    // }
}
</script>
